<template>
  <div>
    <v-card class="mb-7">
      <v-card-text class="d-flex align-center flex-wrap">
        <v-card-title class="pa-0">Information</v-card-title>
        <v-spacer></v-spacer>
      </v-card-text>

      <v-row class="align-center">
        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Employee ID</v-list-item-subtitle>
              <v-list-item-title>{{ user.employee?.employee_id ?? '--' }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Title</v-list-item-subtitle>
              <v-list-item-title>{{ user.employee?.title ?? '--' }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Role</v-list-item-subtitle>
              <v-list-item-title>
                {{ user.roles.map(role => `${t(role.name_translation)}`).join(', ') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Benefit Tier</v-list-item-subtitle>
              <v-list-item-title>{{ user.employee?.tier?.name ?? '--' }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'
import { t } from '@/plugins/i18n'
import { mdiPlus } from '@mdi/js'

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const resource = ref(null)

    onMounted(async () => {})

    return {
      t,

      icons: {
        mdiPlus,
      },
      resource,
    }
  },
}
</script>

<style lang="scss" scoped></style>
