var render = function () {
  var _vm$user$employee$emp, _vm$user$employee, _vm$user$employee$tit, _vm$user$employee2, _vm$user$employee$tie, _vm$user$employee3, _vm$user$employee3$ti;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card', {
    staticClass: "mb-7"
  }, [_c('v-card-text', {
    staticClass: "d-flex align-center flex-wrap"
  }, [_c('v-card-title', {
    staticClass: "pa-0"
  }, [_vm._v("Information")]), _c('v-spacer')], 1), _c('v-row', {
    staticClass: "align-center"
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Employee ID")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$user$employee$emp = (_vm$user$employee = _vm.user.employee) === null || _vm$user$employee === void 0 ? void 0 : _vm$user$employee.employee_id) !== null && _vm$user$employee$emp !== void 0 ? _vm$user$employee$emp : '--'))])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Title")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$user$employee$tit = (_vm$user$employee2 = _vm.user.employee) === null || _vm$user$employee2 === void 0 ? void 0 : _vm$user$employee2.title) !== null && _vm$user$employee$tit !== void 0 ? _vm$user$employee$tit : '--'))])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Role")]), _c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.user.roles.map(function (role) {
    return "".concat(_vm.t(role.name_translation));
  }).join(', ')) + " ")])], 1)], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-list-item', {
    attrs: {
      "two-line": ""
    }
  }, [_c('v-list-item-content', [_c('v-list-item-subtitle', [_vm._v("Benefit Tier")]), _c('v-list-item-title', [_vm._v(_vm._s((_vm$user$employee$tie = (_vm$user$employee3 = _vm.user.employee) === null || _vm$user$employee3 === void 0 ? void 0 : (_vm$user$employee3$ti = _vm$user$employee3.tier) === null || _vm$user$employee3$ti === void 0 ? void 0 : _vm$user$employee3$ti.name) !== null && _vm$user$employee$tie !== void 0 ? _vm$user$employee$tie : '--'))])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }