<template>
  <div class="user-tab-reward-points">
    <v-row>
      <v-col cols="12">
        <profile :user="user" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Profile from './employee/Profile.vue'

export default {
  components: { Profile },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  setup(props) {},
}
</script>
